import React from "react"

import { Modal, Button, Form } from "react-bootstrap"

const modal = ({ show, handleClose }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Solicitar Cotización</Modal.Title>
      </Modal.Header>
      <Form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" netlify>
      <input type="hidden" name="form-name" value="contact" />
        <Modal.Body>
          <Form.Group controlId="formGroupEmail">
            <Form.Control name='name' type="text" placeholder="Nombre" required />
          </Form.Group>
          <Form.Group controlId="formGrouptelephone">
            <Form.Control
              type="text"
              name='telephone'
              placeholder="Número de teléfono"
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupEmail">
            <Form.Control
              type="email"
              name='email'
              placeholder="Correo electrónico"
              required
            />
          </Form.Group>
          <Form.Group controlId="formGroupComments">
            <Form.Control
              as="textarea"
              name='comments'
              rows="3"
              placeholder="¿En que podemos ayudarte?"
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="success">
            Enviar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default modal
